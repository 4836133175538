import { Alert, Button, Modal, Table, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";


export function UploadCheckTableCSVModal(props) {

    const data = props.Data;
    const columns = props.Columns;
    const [loading, setLoading] = React.useState(false);
    const [reAuthModal, setReAuthModal] = React.useState(false);

    const onConfirm = async () => {
        setLoading(true);
        setReAuthModal(true);
    };

    const totalDataCount = data?.length || 0;

    return (
        <>
            <Modal
                title="CSV Daten bestätigen und endgültig hochladen"
                open={props.Open}
                onCancel={props.OnCancel}
                footer={[
                    <Button key="back" onClick={props.OnCancel} loading={loading}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={onConfirm}>
                        Bestätigen
                    </Button>,
                ]}
                maskClosable={false}
                width={"90%"}
                centered
            >
                <Typography.Text strong>Bitte überprüfen Sie die Daten und bestätigen Sie die Daten. Dann werden diese abgespeichert. Für die bestätigung ist eine erneute Autentifizierung erforderlich.
                
                </Typography.Text>
                <Alert
                    message={`Es wurden ${totalDataCount} Datensätze gefunden.`}
                    type="info"
                    showIcon
                    style={{ marginTop: 10 }}
                />
                <Alert
                    message="Bitte überprüfen Sie die Daten sorgfältig, bevor Sie fortfahren! Aufgrund von inkonsistenten Daten können Fehler auftreten. - Wir entfernen Duplikate und leere Zeilen automatisch, nach bestimmten Regeln z.B. wenn ein Datensatz mehr als 4 leere Felder hat."
                    type="warning"
                    showIcon
                    style={{ marginTop: 10 }}
                />
                <Alert
                    message="Negative Zahlen werden je nach Einstellung beim import umgewandelt. Bitte überprüfen Sie die Einstellungen in den Objekteinstellungen."
                    type="warning"
                    showIcon
                    style={{ marginTop: 10 }}
                />
                <Table
                    dataSource={data?.map((row, index) => {
                        return {
                            ...row,
                            key: index,
                        };
                    })}
                    columns={columns}
                    rowKey={(record) => record.key}
                    pagination={false}
                    scroll={{ y: 600, x: 3000 }}
                    style={{ marginTop: 10 }}
                    loading={props.loading}
                />
            </Modal>
            <ReAuthenticationFormModal Open={reAuthModal} OnSuccess={
                async () => {
                    await props.OnConfirm(data);
                    setReAuthModal(false);
                    setLoading(false);
                }
            } OnCancel={() => {
                setReAuthModal(false);
                setLoading(false);
            }} />
        </>
    )
}