/**
 * @version 2.0
 * @description Stellt das Globale App-Layout dar
 * @returns {React-Object}
 */

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
//eslint-disable-next-line
import { Alert, Button, Input, Layout, Menu, message, Space, Tag, theme, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { auth, logOutUser } from '../../../firebase';
import { updateDarkMode } from '../../../redux/userSlices';
import { DarkModeRounded, LightModeRounded, LogoutOutlined } from '@mui/icons-material';
import { adminSidebarItems, pmaSidebarItems, plSidebarItems, allProjectsSidebarItems, returnProjectsAsSidebarChildren, hrSidebarIstems, pbSideBarItems, managementSidebarItems, accountingSidebarItems, projectAssistanceItems } from '../../../layout/config/sidebarItems';
import _ from 'lodash';
import { resetStore } from '../../../redux/store';
import { checkRequestedRole } from '../../../modules/userRoles/userRoles';
import { DateTime } from 'luxon';
import { LogoContainer } from './LogoContainer';
import { GlobalSearch } from '../globalMongoSearch/GlobalSearch';

const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;
const timeoutDuration = 5 * 60 * 1000;

function LayoutSiderHeader({ children }) {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
//eslint-disable-next-line
    const [current, setCurrent] = useState('/');
//eslint-disable-next-line
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //eslint-disable-next-line
    const firUid = useSelector((state) => state.userState.firId);
    const currentUser = useSelector((state) => state.userState.firebaseUser);
    const mongoUser = useSelector((state) => state.userState.mongoUser);
    const darkMode = useSelector((state) => state.userState.darkMode) || false;
    const projects = useSelector((state) => state.projectState.projects);
    const uid = mongoUser?._id
    const [newSidebarItems, setNewSidebarItems] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();

//eslint-disable-next-line
    const handleDarkModeChange = () => {
        dispatch(updateDarkMode(!darkMode));
    };

    useEffect(() => {
        // Überprüfen, ob der Benutzer den manuellen Modus aktiviert hat
        const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (prefersDarkMode) {
            // Der Benutzer bevorzugt den Dark Mode
            console.log('Dark Mode aktiviert');
            dispatch(updateDarkMode(true));
        } else {
            // Der Benutzer bevorzugt den Light Mode
            console.log('Light Mode aktiviert');
            dispatch(updateDarkMode(false));
        }
      }, [theme]);

    const handleLogout = () => {
        dispatch(resetStore());
        logOutUser();
        navigate("/login");
    };

    useEffect(() => {
        const checkRoleAndSetSidebarItems = async () => {
            if ((await checkRequestedRole("administrator")).result) {
                setNewSidebarItems(adminSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("projectLead")).result) {
                setNewSidebarItems(plSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("consultant")).result) {
                setNewSidebarItems(pbSideBarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("management")).result) {
                setNewSidebarItems(managementSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("hr")).result) {
                setNewSidebarItems(hrSidebarIstems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("viewer")).result) {
                setNewSidebarItems(allProjectsSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("accounting")).result) {
                setNewSidebarItems(accountingSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else if ((await checkRequestedRole("project-assistance")).result) {
                setNewSidebarItems(projectAssistanceItems(uid, returnProjectsAsSidebarChildren(projects)))
            } else {
                setNewSidebarItems(pmaSidebarItems(uid, returnProjectsAsSidebarChildren(projects)))
            }
        };

        checkRoleAndSetSidebarItems();
    }, [currentUser, projects, uid]);

    const [emailVerified, setEmailVerified] = useState(false);
    useEffect(() => {
            setEmailVerified(auth.currentUser.emailVerified);
    }, [auth]);

    useEffect(() => {
        let timeoutId;
      
        const startTimeout = () => {
          timeoutId = setTimeout(() => {
            if (!navigator.onLine) {
              messageApi.error("Du wurdest automatisch ausgeloggt, da du 5 Minuten keine Internetverbindung hattest.");
              logOutUser();
            }
          }, timeoutDuration);
        };
      
        const handleOnline = () => {
          messageApi.success("Die Internetverbindung wurde wiederhergestellt.");
          clearTimeout(timeoutId);
        };
      
        const handleOffline = () => {
          messageApi.error("Die Internetverbindung wurde unterbrochen. Bitte überprüfe deine Internetverbindung. Du wirst nach 5 Minuten ohne Internetverbindung automatisch ausgeloggt.");
          startTimeout();
        };
      
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
      
        // Cleanup-Funktion
        return () => {
          clearTimeout(timeoutId);
          window.removeEventListener('offline', handleOffline);
          window.removeEventListener('online', handleOnline);
        };
      }, []);
      
      
      

    return (
        <Layout style={{ minHeight: "100vh"}}>
            <Header style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: '#DA3E52',
                zIndex: 1,
                top: 0,
                width: '100%',
                position: 'fixed',
            }}>
            <LogoContainer />
            {/* <GlobalSearch /> */}
                <div>
                    <Space style={{
                        float: "right",
                        marginTop: "11px",
                    }}>
                        <Button type="icon" shape="circle" size={"large"} onClick={handleDarkModeChange}>
                            {darkMode ? <LightModeRounded /> : <DarkModeRounded />}
                        </Button>
                        <Button type="icon" shape="circle" icon={<LogoutOutlined />} size={"large"} onClick={handleLogout} />
                    </Space>
                </div>

            </Header>
            <Layout style={{
                marginTop: 64
            }}>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100%',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                width={"15%"}
                theme={"light"}
            >
                <div style={{
                    padding: 11
                }}>
                {!emailVerified && <Tag color={!emailVerified && "red"}>{!emailVerified && "E-Mail noch nicht verifiziert"}</Tag>}
                </div>
                <Menu
                    style={{
                        height: '100vh',
                    }}
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={['4']}
                    defaultOpenKeys={['projectmanagement']}
                    items={newSidebarItems}
                    onClick={(e) => { setCurrent(e.key); navigate(`/${e.key}`) }}
                />
            </Sider>
            <Layout>
                <Content
                    style={{
                        // margin: '24px 16px 0',
                        // overflow: 'initial',
                    }}
                >
                
                    <div
                        style={{
                            padding: 24,
                            textAlign: 'center',
                            background: colorBgContainer,
                        }}
                    >
                        {
                            DateTime.now().toISODate() < "2024-03-21" && <div style={{
                            marginBottom: 10

                        }}>

                            <Alert message={
                                <Typography.Text>
                                    Wichtiger Hinweis: Wir führen Sicherheitsverbesserungen ein. Jede Systeminteraktion wird bewertet, und der Zugriff wird entsprechend gesteuert. Zusätzlich werden Login-Token, die beispielsweise dafür sorgen, dass man sein Passwort nicht eingeben muss, ebenfalls widerrufen. <Typography.Link onClick={() => navigate('/changelog')}>Mehr erfahren</Typography.Link>
                                </Typography.Text>

                            } type="warning" showIcon style={{textAlign: "left"}} />

                        </div>
                        }

                        {children}
                    </div>
                </Content>
            </Layout>
            <Footer
                    style={{
                        width: '100%', 
                        position: 'fixed',
                        bottom: 0,
                    }}
                >
                    © 2023 - 2024 | I.K. Hofmann Projektmanagement GmbH | 
                    <a href='https://www.hpm.services/datenschutz'> Datenschutz </a> | 
                    <a href='https://www.hpm.services/impressum'> Impressum </a> |
                    <a href='https://www.hpm.services/datenschutz'> Cookie Nutzung </a>
                </Footer>
            </Layout>
        </Layout>
    );
};

export { LayoutSiderHeader };